.navbar {
  /* background-color: #0a785d; */
  background-image: linear-gradient(#f1413b, white);
  /* border: 2px solid yellow; */
  font-size: 50px;
  display: flex;
  /* justify-content: space-between; */
  height: 13vh;
}
.nav-link {
  font-size: 25px;
  font-family: 'Josefin Sans', sans-serif;
}
.collapse {
  /* border: 3px solid pink; */
  display: flex;
  justify-content: flex-end;
}
#logo-image {
  /* /* border: 1px solid black; */
  width: 3.5em;
  height: 100%;
}
.form-group > h2 {
  font-family: "Rock Salt", cursive;
  /* color: white; */
}
#form {
  /* border: 2px solid blue; */
  /* height: 72.5vh; */
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
  /* align-items: center; */
  /* justify-content: center; */
  /* border: 5px solid gray; */
  /* width: 100vw;  */
}
.descriptions-row {
  /* border: 5px solid maroon; */
  height: 65vh;
  background-image: linear-gradient(white 0%, #059941 50%, #076c3e 100%);
  width: 100vw;
  display: flex;
  align-items: center;
  /* overflow-y: auto; */
}
.text-secondary {
  color: gold;
}
ul {
  /* display: flex; */
  text-align: center;
}
li {
  list-style-type: none;
}
.scroll-rows {
  border: 4px solid black;
  height: 56.5vh;
  min-width: 200px;
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  position: relative;
}
/* .scroll-rows::-webkit-scrollbar {
  width: 1em;
} */
/*  
.scroll-rows::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.scroll-rows::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */
.button-row {
  /* background-image: linear-gradient(white 15%, #f45a2d 75%, white); */
  background: white; 
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  /* border: 2px solid green; */
}
.footer {
  text-align: center;
  /* background-image: linear-gradient(black, #0a785d); */
  background: white;
  height: 6.5vh;
  color: black;
}
#footer-text {
  margin-top: 10px;
  font-size: 1em;
  font-family: 'Josefin Sans', sans-serif;
  /* border: 2px solid white; */
  display: flex;
  justify-content: center;
  align-self: center;
}
.footer-row {
  /* background: black; */
}
.city-row {
  /* border: 2px solid pink; */
  display: flex;
  align-items: flex-end;
  width: 100vw;
  height: 24vh;
  background-image: linear-gradient(white, #A7A7A7 50%);
  /* background-color: #A7A7A7; */
}
col {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
}
.city-image {
  position: absolute;
  width: 100%;
  height: 15em;
  overflow-x: hidden;
  overflow-y: inherit;
  z-index: 2;
  /* border: 2px solid pink; */
}
.eButton {
  font-family: 'helvetica', sans-serif;
  z-index: 1;
  align-self: center;
  border: 2px solid black;
  background-color: #f45a2d;
  border-radius: 15px;
  width: 13em;
  height: 6em;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  animation : wobble 3s;
  /* transition: width 2s, height 4s; */
}
.eButton-div {
  /* border: 2px solid purple; */
  width: 100vw;
  display: flex;
  text-align: center;
  /* align-items: center; */
  justify-content: center;
}
.button-div {
  /* border: 2px solid purple; */
  background: #A7A7A7;
  width: 100vw;
  display: flex;
  text-align: center;
  /* align-items: center; */
  justify-content: center;
}
.services-button {
  font-family: 'Josefin Sans', sans-serif;
  z-index: 1;
  /* align-self: auto; */
  border: 2px solid black;
  background-color: #0A9C91;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: 9em;
  font-size: 30px;
}
.button-text {
  text-decoration: none;
  padding: 10px;
  padding-top: 20px;
  align-self: center;
}
.services-button-text {
  text-decoration: none;
  padding: 5px;
  padding-top: 15px;
  align-self: center;
  font-size: 20px;
}
.eButton:hover {
  color: black;
}
.arch-image {
  position: absolute;
  width: 100vw;
  height: 55vh;
  opacity: 0.2;
  /* overflow-y: auto; */
}
#small-logo-image{
  position: absolute;
  width: 50vw;
  height: 55vh;
  opacity: 0.2;
  /* border: 2px solid purple; */
  /* overflow-y: auto; */
}
/* @keyframes swing {
  from {align-self: flex-end;}
  to {align-self: flex-start;}
} */
.title-text {
  font-size: 5em;
  z-index: 1;
  font-family: cooper-black-std, serif;
  animation: bobble 3s;
  transform: translate3d(20px, 20px, 0px);
  text-shadow: 1px 1px white;
  width: 90vw;
  text-align: center;
  /* border: 2px solid pink; */
}
@keyframes bobble {
  0% {
      transform: translate3d(50%, 20px, 0px);
      animation-timing-function: ease-in;
  }
  50% {
      transform: translate3d(50px, 20px, 0px);
      animation-timing-function: ease-out;
  }
  100% {
      transform: translate3d(20px, 20px, 0px);
  }
}
@keyframes gobble {
  0% {
      transform: translate3d(-100%, 0px, 0px);
      animation-timing-function: ease-in;
  }
  50% {
      transform: translate3d(-50%, 0px, 0px);
      /* animation-timing-function: ease-out; */
  }
  100% {
      transform: translate3d(0px, 0px, 0px);
  }
}
@keyframes wobble {
  0% {
      font-size: 6px;
      transform: translate3d(-100%, 0px, 0px);
      animation-timing-function: ease-in;
  }
  50% {
      transform: translate3d(50%, 0px, 0px);
      font-size: 10px;
  }
  100% {
      transform: translate3d(0px, 0px, 0px);
      font-size: 15px;
  }
}
@keyframes hobble {
  0% {
      /* font-size: 6px; */
      transform: translate3d(-25%, 0px, 0px);
      animation-timing-function: ease-in;
      /* width: 25%; */
  }
  50% {
      transform: translate3d(5%, 0px, 0px);
      animation-timing-function: ease-out;
      /* width: 50%; */
  }
  100% {
      transform: translate3d(0px, 0px, 0px);
      /* width: 100%; */
  }
}
@keyframes fade-in {
  0% {
    /* transform: translate3d(25%, 0px, 0px); */
      opacity: 0;
      animation-timing-function: ease-in;
  }
  50% {
      /* transform: translate3d(15%, 0px, 0px); */
      opacity: 0.5;
      animation-timing-function: ease-out;
  }
  100% {
    transform: translate3d(0%, 0px, 0px);
      opacity: 1;
  }
}
@keyframes fade-out {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0.5;
  }
  100% {
      opacity: 0;
  }
}
.tagline-text {
  font-size: 3em;
  z-index: 1;
  font-family: 'helvetica', sans-serif;
  /* align-self: flex-end; */
  padding-right: 5px;
  animation : gobble 2s; 
  text-shadow: 1px 1px white;
  /* border: 2px solid gold; */
  width: 100vw;
  text-align: center;
  /* animation-direction: reverse; */
  /* justify-content: right; */
}
.card {
  height: 12em;
  width: 15em;
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
  display: flex;
  align-items: center;
  color: black;
}
.card:hover {
  text-decoration: none;
}

.card-col {
  display: flex;
  flex-direction: column;
  /* border: 2px solid green; */
  height: 65vh;
  align-items: center;
  justify-content: space-around;
  /* border: 2px solid blue; */
  
}
.services-row {
  background-image: linear-gradient(white 10%, #f4971e 100%);
  font-family: 'helvetica', sans-serif;
  position: relative;
  border: 2px solid black;
  width: 65vw;
  text-align: center;
  z-index: 3;
}
.icon {
  background-image: none;
  width: 80%;
  height: 75%;
}
.bigicon {
  width: 40%;
  height: 80%;
  background: none;
  animation: wobble 4s;
}
.bigcard {
  border: 5px solid white;
  background-image: linear-gradient(#0A9C91, black);
  width: 100vw;
  height: 60vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.bigcard h3 {
  font-family: cooper-black-std, serif;
  font-size: 200%;
}
.bigscroll-row {
  border: 4px solid black;
  height: 80.5vh;
  /* overflow-y: scroll;  */
  overflow-x: hidden;
  width: 100vw;
  display: flex;
  justify-content: center;
}
.bigcard1 {
  background: #f2413b;
}
.bigcard2 {
  background: #f55a2c;
}
.bigcard3 {
  background: #f9951b;
}
.bigcard4 {
  background: #059941;
}
.bigcard5 {
  background: #06673a;
}
.text-area {
  background-color: white;
  width: 75%;
  height: 40vh;
  border-radius: 5px;
  border: 4px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: arial;
}
#text-area1 {
  height: 56vh;
}
#text-area2 {
  height: 50vh;
}
.text-area p {
  margin: 5px;
}
.form-rows {
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* text-align: center;
  height: 80.5vh;
  min-width: 200px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden; */
  /* background-image: url("images/philly-fountain.jpg"); */

}
.moving-row {
  /* background-image: linear-gradient(white, #f45a2d 25%, white 75%); */
  width: 100vw;
  /* min-height: 20vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid black; */
}
.moving-icon {
  /* width: 70%; */
}
.form-row2 {
  text-align: center;
}
.form-area {
  width: 100vw;
  height: 65vh;
  /* z-index: 1; */
  /* background-image: linear-gradient(#0A9C91, black); */
}
.moving-text {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 40px;
  text-align: center;
}
.fountain-image {
  position: absolute;
  width: 100vw;
  height: 60%;
  opacity: 0.15;
}
.question-row {
  display: flex;
  align-items: center;
  animation: hobble 3s;
  font-family: 'Josefin Sans', sans-serif;
  display: flex;
  justify-content: center;
  width: 100vw;
  text-align: center;
  /* height: 12vh; */
  /* border: 2px solid black; */
}
.question-row h1 {
  font-size: 3em;
  margin: 15px 0 15px 0;
}
#moving-check {
  margin-top: 8px;
  margin-right: 10px;
  zoom: 1.5;
  transform: scale(1.5);
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
}
.check {
  margin-top: 8px;
  margin-right: 10px;
  zoom: 1.5;
  transform: scale(1.5);
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
}
.other-row {
  /* background: #0A9C91; */
  width: 100vw;
  /* height: 10vh; */
  /* border: 2px solid gold; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Josefin Sans', sans-serif;
}
.moving-q {
  /* border: 2px solid black; */
  display: flex;
  /* justify-content: center; */
}
.hidden {
  display: none;
  animation: fade-out 1.5s;
}
.calendar {
  animation: fade-in 1.5s;
  font-family: 'Josefin Sans', sans-serif;
  display: flex;
  justify-content: space-around;
  text-align: center;
  /* border: 2px solid white; */
  width: 50%;
}
.packing-calendar {
  animation: fade-in 1.5s;
  font-family: 'Josefin Sans', sans-serif;
  display: flex; 
  /* justify-content: space-around; */
  /* border: 2px solid white; */
 
}
.date-picker {
  text-align: center;
}
.date-picker2 {
  text-align: center;
}
.not-moving {
  animation: fade-out 3s;
}
/* .other-services {
  animation: fade-out 2.5s;
  animation-direction: reverse;
} */
.checkboxes-row {
  /* background-image: linear-gradient(#f9951b, white); */
  /* height: 30vh; */
  display: flex;
  justify-content: center;
  width: 100vw;
  /* align-items: center; */
}
.boxes {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 30px;
  /* border: 2px solid red; */
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.left-checks {
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 25vw;
}
.right-checks {
  /* border: 2px solid yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 25vw;
}
.btn-row {
  /* border: 2px solid pink; */
  width: 100vw;
  display: flex;
  justify-content: center;
  /* background: black; */
  /* height: 10vh; */
}
.submit-button {
  font-family: 'Josefin Sans', sans-serif;
  border: 2px solid black;
  background-color: #f45a2d;
  border-radius: 15px;
  width: 15em;
  display: flex;
  justify-content: center;
  color: white;
}
.submit-button:hover {
  color: black;
  cursor: pointer;
}
.services-calendar {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.scroll-text {
  display: flex;
  text-align: center;
  flex-direction: column;
  color: white;
  margin-top: 30px;
}
.details-row {
  background-image: linear-gradient(white, #A7A7A7);
  /* border: 2px solid orange; */
  display: flex;
  justify-content: space-around;
  text-align: center; 
  align-items: center;
  font-family: 'Josefin Sans', sans-serif;
  width: 100vw;
}
.basicinfo-row {
  display: flex;
  width: 100vw;
  justify-content: space-around;
}
.infoareas-row {
  display: flex;
  width: 100vw;
  justify-content: space-around;
}
Textarea {
  text-align: center;
}
.extra-info {
  animation: fade-in 1.5s;
  font-family: 'Josefin Sans', sans-serif;
  display: flex;
  flex-direction: column;
  text-align: center;
  /* justify-content: center; */
  border: 2px solid black;
  background-image: linear-gradient(white, #f9951b);
  width: 100vw;
  
}
.extra-info li {
  font-size: 25px;
  /* border: 2px solid yellow; */
}
.extra-info Link {
  color: black;
  font-size: 30px;
}
.extra-info ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  /* flex-direction: column;
  align-items: center; */
  /* text-align: center; */
  /* border: 2px solid white; */
  /* justify-content: center; */
}
.movinginfo-row {
  /* border: 2px solid black; */
  /* text-align: center; */
  width: 100vw;
  display: flex;
  justify-content: center;
  font-size: 25px;
}
.magnifyingglass-icon {
  width: 5%;
  /* border: 2px solid yellow; */
}
@media screen and (max-width: 500px) {
  .magnifyingglass-icon {
    display: none;
  }
}
.movinginfo-title {
  width: 100vw;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 20px 20px 20px 0;
  /* border: 2px solid white; */
}
.radio {
  margin: 0 5px 0 5px;
}
.infoq {
  /* border: 2px solid yellow; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 10px 0 10px 0;

}
.add {
  /* border: 2px solid yellow; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.packorunpack-add {
  width: 100vw;
}
.packorunpack-addressq {
  display: flex;
  justify-content: center;
  width: 100vw;
}
.mount-addressq {
  display: flex;
  justify-content: center;
  /* border: 2px solid white; */
  text-align: center;
  width: 100vw;
}
.mount-detailsq {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100vw;
  /* border: 2px solid black; */
}
.packorunpack-bedroomsq {
  /* border: 2px solid green; */
  display: flex;
  justify-content: center;
  width: 100vw;
}
.unpack-bedroomsq {
  /* border: 2px solid white; */
  /* display: flex;
  justify-content: center;
  width: 100vw; */
}
.otherinfo-row {
  display: flex;
  width: 100vw;
  text-align: center;
  display: flex;
  /* border: 2px solid pink; */
  justify-content: center;
}
.pack-row {
  display: flex;
  justify-content: center;
  width: 100vw;
}
.unpack-row {
  display: flex;
  justify-content: center;
  width: 100vw;
}
.detailsq {
  display: flex;
  flex-direction: column;
}
.current-officesq {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 18vh; */
  /* border: 2px solid purple; */
}
.current-storageq {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 18vh; */
  /* border: 2px solid purple; */
}
.crate-row {
  display: flex;
  justify-content: center;
}
.boxesq {
  text-align: left;

}
.unpack-boxesq {
  display: flex;
  /* border: 2px solid white; */
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mount-row {
  display: flex;
  justify-content: center;
  width: 100vw;
}
.mount-row h2 {
  text-align: right;
}